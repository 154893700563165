import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloProvider } from '@apollo/client/react';
import { setContext } from '@apollo/client/link/context';

import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { UMNProvider } from './contexts/UMNContext';
import { AgenciaProvider } from './contexts/AgenciaContext';
import { DateProvider } from './contexts/DateContext';
import { RoleProvider } from './contexts/RoleContext';
import { FilterGerenciaProvider } from './contexts/FilterContext'
import { ModalWidgetProvider } from './contexts/ModalWidgetContext'
import { LoadingProvider } from './contexts/LoadingContext'
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import store from './store';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL_GRAPHQL || "https://apitdpbizdev.tedepasa.com/graphql",
});


// const uploadLink = createUploadLink({
//   uri: process.env.REACT_APP_API_URL_GRAPHQL || "http://dev.mastarjeta.net:4002/graphql",
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),

});


ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <LoadingProvider>
              <SettingsProvider>
                <BrowserRouter>
                  <AuthProvider>
                    <AgenciaProvider>
                      <UMNProvider>
                        <DateProvider>
                          <RoleProvider>
                            <ModalWidgetProvider>
                              <FilterGerenciaProvider>
                                <App />
                              </FilterGerenciaProvider>
                            </ModalWidgetProvider>
                          </RoleProvider>
                        </DateProvider>
                      </UMNProvider>
                    </AgenciaProvider>
                  </AuthProvider>
                </BrowserRouter>
              </SettingsProvider>
            </LoadingProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
      </ApolloProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
